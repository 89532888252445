import { Injectable } from '@angular/core';
import { AlertType } from '@escript/core-models';
import { TranslocoService } from '@ngneat/transloco';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { IndividualConfig } from 'ngx-toastr/toastr/toastr-config';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(
    private toastrService: ToastrService,
    private translateService: TranslocoService,
  ) {}

  info(message: string, options?: Partial<IndividualConfig>): ActiveToast<any> {
    return this.showAlert(message, AlertType.INFO, options);
  }

  success(message: string, options?: Partial<IndividualConfig>): ActiveToast<any> {
    return this.showAlert(message, AlertType.SUCCESS, options);
  }

  error(message: string, options?: Partial<IndividualConfig>): ActiveToast<any> {
    return this.showAlert(message, AlertType.ERROR, options);
  }

  warning(message: string, options?: Partial<IndividualConfig>): ActiveToast<any> {
    return this.showAlert(message, AlertType.WARNING, options);
  }

  private showAlert(message: string, type: AlertType, options?: Partial<IndividualConfig>) {
    options = {
      timeOut: type === AlertType.ERROR ? 0 : 15000,
      closeButton: true,
      newestOnTop: type !== AlertType.ERROR,
      ...options,
    };
    return this.showToast(message, type, options);
  }

  private showToast(
    message: string,
    type: AlertType,
    options?: Partial<IndividualConfig>,
  ): ActiveToast<any> {
    message = this.translateService.translate(message);
    switch (type) {
      case AlertType.SUCCESS:
        return this.toastrService.success(message, '', options);
        break;
      case AlertType.WARNING:
        return this.toastrService.warning(message, '', options);
        break;
      case AlertType.ERROR:
        return this.toastrService.error(message, '', options);
        break;
      case AlertType.INFO:
        return this.toastrService.info(message, '', options);
        break;
    }
  }
}
