import { AssetType } from '../../asset-type';
import { TradeAsset } from '../../trade-asset.enum';
import { IExchangeSymbolInfoEntity } from './symbol-exchange-info-entity.dto';

export class IExchangeAssetLinks {
  homepage: string[];
  blockchainSite: string[];
  officialForumUrl: string[];
  chatUrl: string[];
  announcementUrl: string[];
  twitterScreenName: string;
  facebookUsername: string;
  bitcointalkThreadIdentifier: string;
  telegramChannelIdentifier: string;
  subredditUrl: string;
  reposUrl: {
    github: [];
    bitbucket: [];
  };
}

export class IExchangeAsset {
  id: string;
  updatedDate: Date = new Date();
  externalUpdatedDate: Date | null = null;
  symbol: TradeAsset;
  imgThumb: string | null;
  imgSmall: string | null;
  imgLarge: string | null;
  name: string;
  type: AssetType;
  contract: string | null;
  decimals: number | null;
  exchangeSymbols!: IExchangeSymbolInfoEntity[];
  status: AssetStatus | null;

  assetPlatformId: string | null;
  blockTimeInMinutes: number | null;
  hashingAlgorithm: string | null;
  categories: string[] | null;
  links: IExchangeAssetLinks | null;
  description: { en: string } | null;
  publicNotice: string | null;
  additionalNotices: string[] | null;
  countryOrigin: string | null;
  genesisDate: string | null;
  contractAddress: string | null;
  sentimentVotesUpPercentage: number | null;
  sentimentVotesDownPercentage: number | null;
  marketCapRank: number | null;
  coingeckoRank: number | null;
  coingeckoScore: number | null;
  developerScore: number | null;
  communityScore: number | null;
  liquidityScore: number | null;
  publicInterestScore: number | null;
  publicInterestStats: {
    alexaRank: number;
    bingMatches: string;
  } | null;
  externalStatusUpdates: [] | null;
  externalLastUpdated: string | null;
}

export class IExchangeAssetWithFavourite extends IExchangeAsset {
  isFavorite: boolean;
  favoriteId: number | undefined;
  exchangeId: number;
}

export enum AssetStatus {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
  ERROR = 'ERROR',
}
