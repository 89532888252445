import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { ILoginResponseDto, IUserProfileSettingsEntityDto } from '@escript/core-models';

export type AuthState = ILoginResponseDto;
export function createInitialState(): AuthState {
  return {} as AuthState;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'auth', resettable: true })
export class AuthStore extends Store<AuthState> {
  constructor() {
    super(createInitialState());
  }

  updateSettings(settings: IUserProfileSettingsEntityDto) {
    this.update((state: AuthState) => {
      return {
        ...state,
        profile: {
          ...state.profile,
          settings: settings as any,
        },
      };
    });
  }

  setExchangeConnectionsFlag(exchangeId: number, connected: string | Date | null) {
    const exchangeUserSettings = this.getValue()?.profile.settings.exchangeSettings.map((ex) => {
      if (ex.exchangeId === exchangeId) {
        return { ...ex, connected };
      }
      return ex;
    }) as any[];

    this.update((state: AuthState) => {
      const { profile } = state;
      return {
        ...state,
        profile: {
          ...profile,
          settings: {
            ...profile.settings,
            exchangeSettings: exchangeUserSettings,
          },
        },
      };
    });
  }
}
