export const PriceCandle = 'kline_1m';

export const PRIMARY_SYMBOL = 'BTCUSDT';
export const SECONDARY_SYMBOL = 'ETHUSDT';

export const BASE_STREAM_TYPES: ExchangeStreamTypes[] = [
  'aggTrade',
  'miniTicker',
  // 'depth',
  'kline_1m',
];

export type CommonKlineTypes =
  | 'kline_1m'
  | 'kline_5m'
  | 'kline_3m'
  | 'kline_15m'
  | 'kline_30m'
  | 'kline_1h'
  | 'kline_2h'
  | 'kline_4h'
  | 'kline_6h'
  | 'kline_8h'
  | 'kline_12h'
  | 'kline_1d'
  | 'kline_3d'
  | 'kline_1w';

export type KlineTypes = CommonKlineTypes | 'kline_1M';

export type CommonStreamTypes = CommonKlineTypes | 'ticker';

export type ExchangeStreamTypes =
  | KlineTypes
  | 'ticker'
  | 'aggTrade'
  | 'miniTicker'
  | '24hrMiniTicker'
  | 'bookTicker'
  | 'trade'
  | 'depth';

export type StreamTopic = string;
