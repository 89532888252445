// Preserve order because it is used in other logic
export enum ServerOrderStatus {
  NEW = 'NEW',
  PROCESSING = 'PROCESSING', // If server order is being placed on the exchange
  SERVER_REJECTED_NO_BALANCE = 'SERVER_REJECTED_NO_BALANCE',
  SERVER_REJECTED_ERROR = 'SERVER_REJECTED_ERROR',
  PLACED = 'PLACED', // If server order is placed as exchange order successfully with NEW status
  PARTIALLY_FILLED = 'PARTIALLY_FILLED',
  EXPIRED = 'EXPIRED',
  REJECTED = 'REJECTED',
  PENDING_CANCEL = 'PENDING_CANCEL',
  CANCELED = 'CANCELED',
  FILLED = 'FILLED',
  BATCH_PROCESSED = 'BATCH_PROCESSED',
  BATCH_PROCESSED_WITH_ERRORS = 'BATCH_PROCESSED_WITH_ERRORS',
}
