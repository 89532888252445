import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { TraceService } from '@sentry/angular';

@Injectable({ providedIn: 'root' })
export class AppInitService {
  constructor(
    private traceService: TraceService,
    private translocoService: TranslocoService,
  ) {}
  init(): Promise<any> {
    const lang = 'en';
    this.translocoService.setDefaultLang(lang);
    this.translocoService.setActiveLang(lang);
    return this.translocoService
      .load(lang)
      .toPromise()
      .then(() => this.translocoService.load('global/' + lang).toPromise())
      .then(() => this.translocoService.load('error/' + lang).toPromise())
      .then(() => this.translocoService.load('health/' + lang).toPromise())
      .then(() => this.translocoService.load('status/' + lang).toPromise());
  }
}
