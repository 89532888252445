export enum TradePair {
  ETHBTC = 'ethbtc',
  LTCBTC = 'ltcbtc',
  BNBBTC = 'bnbbtc',
  NEOBTC = 'neobtc',
  QTUMETH = 'qtumeth',
  EOSETH = 'eoseth',
  SNTETH = 'snteth',
  BNTETH = 'bnteth',
  GASBTC = 'gasbtc',
  BNBETH = 'bnbeth',
  BTCUSDT = 'btcusdt',
  ETHUSDT = 'ethusdt',
  OAXETH = 'oaxeth',
  DNTETH = 'dnteth',
  MCOETH = 'mcoeth',
  MCOBTC = 'mcobtc',
  WTCBTC = 'wtcbtc',
  WTCETH = 'wtceth',
  LRCBTC = 'lrcbtc',
  LRCETH = 'lrceth',
  QTUMBTC = 'qtumbtc',
  YOYOBTC = 'yoyobtc',
  OMGBTC = 'omgbtc',
  OMGETH = 'omgeth',
  ZRXBTC = 'zrxbtc',
  ZRXETH = 'zrxeth',
  STRATBTC = 'stratbtc',
  STRATETH = 'strateth',
  SNGLSBTC = 'snglsbtc',
  BQXBTC = 'bqxbtc',
  BQXETH = 'bqxeth',
  KNCBTC = 'kncbtc',
  KNCETH = 'knceth',
  FUNBTC = 'funbtc',
  FUNETH = 'funeth',
  SNMBTC = 'snmbtc',
  SNMETH = 'snmeth',
  NEOETH = 'neoeth',
  IOTABTC = 'iotabtc',
  IOTAETH = 'iotaeth',
  LINKBTC = 'linkbtc',
  LINKETH = 'linketh',
  XVGBTC = 'xvgbtc',
  XVGETH = 'xvgeth',
  MDABTC = 'mdabtc',
  MDAETH = 'mdaeth',
  MTLBTC = 'mtlbtc',
  MTLETH = 'mtleth',
  EOSBTC = 'eosbtc',
  SNTBTC = 'sntbtc',
  ETCETH = 'etceth',
  ETCBTC = 'etcbtc',
  MTHBTC = 'mthbtc',
  MTHETH = 'mtheth',
  ENGBTC = 'engbtc',
  ENGETH = 'engeth',
  DNTBTC = 'dntbtc',
  ZECBTC = 'zecbtc',
  ZECETH = 'zeceth',
  BNTBTC = 'bntbtc',
  ASTBTC = 'astbtc',
  ASTETH = 'asteth',
  DASHBTC = 'dashbtc',
  DASHETH = 'dasheth',
  OAXBTC = 'oaxbtc',
  BTGBTC = 'btgbtc',
  EVXBTC = 'evxbtc',
  EVXETH = 'evxeth',
  REQBTC = 'reqbtc',
  REQETH = 'reqeth',
  VIBBTC = 'vibbtc',
  VIBETH = 'vibeth',
  TRXBTC = 'trxbtc',
  TRXETH = 'trxeth',
  POWRBTC = 'powrbtc',
  POWRETH = 'powreth',
  ARKBTC = 'arkbtc',
  ARKETH = 'arketh',
  XRPBTC = 'xrpbtc',
  XRPETH = 'xrpeth',
  ENJBTC = 'enjbtc',
  ENJETH = 'enjeth',
  STORJBTC = 'storjbtc',
  STORJETH = 'storjeth',
  BNBUSDT = 'bnbusdt',
  POWRBNB = 'powrbnb',
  KMDBTC = 'kmdbtc',
  KMDETH = 'kmdeth',
  NULSBNB = 'nulsbnb',
  RCNBTC = 'rcnbtc',
  RCNETH = 'rcneth',
  RCNBNB = 'rcnbnb',
  NULSBTC = 'nulsbtc',
  NULSETH = 'nulseth',
  RDNBTC = 'rdnbtc',
  RDNETH = 'rdneth',
  RDNBNB = 'rdnbnb',
  XMRBTC = 'xmrbtc',
  XMRETH = 'xmreth',
  DLTBNB = 'dltbnb',
  WTCBNB = 'wtcbnb',
  DLTBTC = 'dltbtc',
  DLTETH = 'dlteth',
  AMBBTC = 'ambbtc',
  AMBETH = 'ambeth',
  AMBBNB = 'ambbnb',
  BATBTC = 'batbtc',
  BATETH = 'bateth',
  BATBNB = 'batbnb',
  BCPTBTC = 'bcptbtc',
  BCPTETH = 'bcpteth',
  BCPTBNB = 'bcptbnb',
  ARNBTC = 'arnbtc',
  ARNETH = 'arneth',
  GVTBTC = 'gvtbtc',
  GVTETH = 'gvteth',
  CDTBTC = 'cdtbtc',
  CDTETH = 'cdteth',
  GXSBTC = 'gxsbtc',
  GXSETH = 'gxseth',
  NEOUSDT = 'neousdt',
  NEOBNB = 'neobnb',
  POEBTC = 'poebtc',
  POEETH = 'poeeth',
  QSPBTC = 'qspbtc',
  QSPETH = 'qspeth',
  QSPBNB = 'qspbnb',
  BTSBTC = 'btsbtc',
  BTSETH = 'btseth',
  XZCBTC = 'xzcbtc',
  XZCETH = 'xzceth',
  XZCBNB = 'xzcbnb',
  LSKBTC = 'lskbtc',
  LSKETH = 'lsketh',
  LSKBNB = 'lskbnb',
  TNTBTC = 'tntbtc',
  TNTETH = 'tnteth',
  FUELBTC = 'fuelbtc',
  MANABTC = 'manabtc',
  MANAETH = 'manaeth',
  BCDBTC = 'bcdbtc',
  BCDETH = 'bcdeth',
  DGDBTC = 'dgdbtc',
  DGDETH = 'dgdeth',
  IOTABNB = 'iotabnb',
  ADXBTC = 'adxbtc',
  ADXETH = 'adxeth',
  ADABTC = 'adabtc',
  ADAETH = 'adaeth',
  PPTBTC = 'pptbtc',
  PPTETH = 'ppteth',
  CMTBTC = 'cmtbtc',
  CMTETH = 'cmteth',
  CMTBNB = 'cmtbnb',
  XLMBTC = 'xlmbtc',
  XLMETH = 'xlmeth',
  XLMBNB = 'xlmbnb',
  CNDBTC = 'cndbtc',
  CNDETH = 'cndeth',
  LENDBTC = 'lendbtc',
  LENDETH = 'lendeth',
  WABIBTC = 'wabibtc',
  WABIETH = 'wabieth',
  WABIBNB = 'wabibnb',
  LTCETH = 'ltceth',
  LTCUSDT = 'ltcusdt',
  LTCBNB = 'ltcbnb',
  TNBBTC = 'tnbbtc',
  TNBETH = 'tnbeth',
  WAVESBTC = 'wavesbtc',
  WAVESETH = 'waveseth',
  WAVESBNB = 'wavesbnb',
  GTOBTC = 'gtobtc',
  GTOETH = 'gtoeth',
  GTOBNB = 'gtobnb',
  ICXBTC = 'icxbtc',
  ICXETH = 'icxeth',
  ICXBNB = 'icxbnb',
  OSTBTC = 'ostbtc',
  OSTETH = 'osteth',
  OSTBNB = 'ostbnb',
  ELFBTC = 'elfbtc',
  ELFETH = 'elfeth',
  AIONBTC = 'aionbtc',
  AIONETH = 'aioneth',
  AIONBNB = 'aionbnb',
  NEBLBTC = 'neblbtc',
  NEBLETH = 'nebleth',
  NEBLBNB = 'neblbnb',
  BRDBTC = 'brdbtc',
  BRDETH = 'brdeth',
  BRDBNB = 'brdbnb',
  EDOBTC = 'edobtc',
  EDOETH = 'edoeth',
  NAVBTC = 'navbtc',
  LUNBTC = 'lunbtc',
  APPCBTC = 'appcbtc',
  APPCETH = 'appceth',
  APPCBNB = 'appcbnb',
  VIBEBTC = 'vibebtc',
  VIBEETH = 'vibeeth',
  RLCBTC = 'rlcbtc',
  RLCETH = 'rlceth',
  RLCBNB = 'rlcbnb',
  INSBTC = 'insbtc',
  INSETH = 'inseth',
  PIVXBTC = 'pivxbtc',
  PIVXETH = 'pivxeth',
  PIVXBNB = 'pivxbnb',
  IOSTBTC = 'iostbtc',
  IOSTETH = 'iosteth',
  STEEMBTC = 'steembtc',
  STEEMETH = 'steemeth',
  STEEMBNB = 'steembnb',
  NANOBTC = 'nanobtc',
  NANOETH = 'nanoeth',
  NANOBNB = 'nanobnb',
  VIABTC = 'viabtc',
  VIAETH = 'viaeth',
  VIABNB = 'viabnb',
  BLZBTC = 'blzbtc',
  BLZETH = 'blzeth',
  BLZBNB = 'blzbnb',
  AEBTC = 'aebtc',
  AEETH = 'aeeth',
  AEBNB = 'aebnb',
  NCASHBTC = 'ncashbtc',
  NCASHETH = 'ncasheth',
  POABTC = 'poabtc',
  POAETH = 'poaeth',
  ZILBTC = 'zilbtc',
  ZILETH = 'zileth',
  ZILBNB = 'zilbnb',
  ONTBTC = 'ontbtc',
  ONTETH = 'onteth',
  ONTBNB = 'ontbnb',
  STORMBTC = 'stormbtc',
  STORMETH = 'stormeth',
  STORMBNB = 'stormbnb',
  QTUMBNB = 'qtumbnb',
  QTUMUSDT = 'qtumusdt',
  XEMBTC = 'xembtc',
  XEMETH = 'xemeth',
  XEMBNB = 'xembnb',
  WANBTC = 'wanbtc',
  WANETH = 'waneth',
  WANBNB = 'wanbnb',
  WPRBTC = 'wprbtc',
  WPRETH = 'wpreth',
  QLCBTC = 'qlcbtc',
  QLCETH = 'qlceth',
  SYSBTC = 'sysbtc',
  SYSETH = 'syseth',
  GRSBTC = 'grsbtc',
  GRSETH = 'grseth',
  ADAUSDT = 'adausdt',
  ADABNB = 'adabnb',
  GNTBTC = 'gntbtc',
  GNTETH = 'gnteth',
  LOOMBTC = 'loombtc',
  LOOMETH = 'loometh',
  LOOMBNB = 'loombnb',
  XRPUSDT = 'xrpusdt',
  REPBTC = 'repbtc',
  REPETH = 'repeth',
  BTCTUSD = 'btctusd',
  ETHTUSD = 'ethtusd',
  ZENBTC = 'zenbtc',
  ZENETH = 'zeneth',
  ZENBNB = 'zenbnb',
  SKYBTC = 'skybtc',
  SKYETH = 'skyeth',
  SKYBNB = 'skybnb',
  EOSUSDT = 'eosusdt',
  EOSBNB = 'eosbnb',
  CVCBTC = 'cvcbtc',
  CVCETH = 'cvceth',
  THETABTC = 'thetabtc',
  THETAETH = 'thetaeth',
  THETABNB = 'thetabnb',
  XRPBNB = 'xrpbnb',
  TUSDUSDT = 'tusdusdt',
  IOTAUSDT = 'iotausdt',
  XLMUSDT = 'xlmusdt',
  IOTXBTC = 'iotxbtc',
  IOTXETH = 'iotxeth',
  QKCBTC = 'qkcbtc',
  QKCETH = 'qkceth',
  AGIBTC = 'agibtc',
  AGIETH = 'agieth',
  AGIBNB = 'agibnb',
  NXSBTC = 'nxsbtc',
  NXSETH = 'nxseth',
  NXSBNB = 'nxsbnb',
  ENJBNB = 'enjbnb',
  DATABTC = 'databtc',
  DATAETH = 'dataeth',
  ONTUSDT = 'ontusdt',
  TRXBNB = 'trxbnb',
  TRXUSDT = 'trxusdt',
  ETCUSDT = 'etcusdt',
  ETCBNB = 'etcbnb',
  ICXUSDT = 'icxusdt',
  SCBTC = 'scbtc',
  SCETH = 'sceth',
  SCBNB = 'scbnb',
  NPXSETH = 'npxseth',
  KEYBTC = 'keybtc',
  KEYETH = 'keyeth',
  NASBTC = 'nasbtc',
  NASETH = 'naseth',
  NASBNB = 'nasbnb',
  MFTBTC = 'mftbtc',
  MFTETH = 'mfteth',
  MFTBNB = 'mftbnb',
  DENTETH = 'denteth',
  ARDRBTC = 'ardrbtc',
  ARDRETH = 'ardreth',
  NULSUSDT = 'nulsusdt',
  HOTBTC = 'hotbtc',
  HOTETH = 'hoteth',
  VETBTC = 'vetbtc',
  VETETH = 'veteth',
  VETUSDT = 'vetusdt',
  VETBNB = 'vetbnb',
  DOCKBTC = 'dockbtc',
  DOCKETH = 'docketh',
  POLYBTC = 'polybtc',
  POLYBNB = 'polybnb',
  HCBTC = 'hcbtc',
  HCETH = 'hceth',
  GOBTC = 'gobtc',
  GOBNB = 'gobnb',
  PAXUSDT = 'paxusdt',
  RVNBTC = 'rvnbtc',
  RVNBNB = 'rvnbnb',
  DCRBTC = 'dcrbtc',
  DCRBNB = 'dcrbnb',
  MITHBTC = 'mithbtc',
  MITHBNB = 'mithbnb',
  BNBPAX = 'bnbpax',
  BTCPAX = 'btcpax',
  ETHPAX = 'ethpax',
  XRPPAX = 'xrppax',
  EOSPAX = 'eospax',
  XLMPAX = 'xlmpax',
  RENBTC = 'renbtc',
  RENBNB = 'renbnb',
  BNBTUSD = 'bnbtusd',
  XRPTUSD = 'xrptusd',
  EOSTUSD = 'eostusd',
  XLMTUSD = 'xlmtusd',
  BNBUSDC = 'bnbusdc',
  BTCUSDC = 'btcusdc',
  ETHUSDC = 'ethusdc',
  XRPUSDC = 'xrpusdc',
  EOSUSDC = 'eosusdc',
  USDCUSDT = 'usdcusdt',
  ADATUSD = 'adatusd',
  TRXTUSD = 'trxtusd',
  NEOTUSD = 'neotusd',
  TRXXRP = 'trxxrp',
  XZCXRP = 'xzcxrp',
  PAXTUSD = 'paxtusd',
  USDCTUSD = 'usdctusd',
  USDCPAX = 'usdcpax',
  LINKUSDT = 'linkusdt',
  LINKTUSD = 'linktusd',
  LINKPAX = 'linkpax',
  LINKUSDC = 'linkusdc',
  WAVESUSDT = 'wavesusdt',
  WAVESTUSD = 'wavestusd',
  WAVESUSDC = 'wavesusdc',
  LTCTUSD = 'ltctusd',
  LTCPAX = 'ltcpax',
  LTCUSDC = 'ltcusdc',
  TRXPAX = 'trxpax',
  TRXUSDC = 'trxusdc',
  BTTBNB = 'bttbnb',
  BTTUSDT = 'bttusdt',
  BNBUSDS = 'bnbusds',
  BTCUSDS = 'btcusds',
  USDSUSDT = 'usdsusdt',
  BTTPAX = 'bttpax',
  BTTTUSD = 'btttusd',
  BTTUSDC = 'bttusdc',
  ONGBNB = 'ongbnb',
  ONGBTC = 'ongbtc',
  ONGUSDT = 'ongusdt',
  HOTBNB = 'hotbnb',
  HOTUSDT = 'hotusdt',
  ZILUSDT = 'zilusdt',
  ZRXBNB = 'zrxbnb',
  ZRXUSDT = 'zrxusdt',
  FETBNB = 'fetbnb',
  FETBTC = 'fetbtc',
  FETUSDT = 'fetusdt',
  BATUSDT = 'batusdt',
  XMRBNB = 'xmrbnb',
  XMRUSDT = 'xmrusdt',
  ZECBNB = 'zecbnb',
  ZECUSDT = 'zecusdt',
  ZECPAX = 'zecpax',
  ZECTUSD = 'zectusd',
  ZECUSDC = 'zecusdc',
  IOSTBNB = 'iostbnb',
  IOSTUSDT = 'iostusdt',
  CELRBNB = 'celrbnb',
  CELRBTC = 'celrbtc',
  CELRUSDT = 'celrusdt',
  ADAPAX = 'adapax',
  ADAUSDC = 'adausdc',
  NEOPAX = 'neopax',
  NEOUSDC = 'neousdc',
  DASHBNB = 'dashbnb',
  DASHUSDT = 'dashusdt',
  NANOUSDT = 'nanousdt',
  OMGBNB = 'omgbnb',
  OMGUSDT = 'omgusdt',
  THETAUSDT = 'thetausdt',
  ENJUSDT = 'enjusdt',
  MITHUSDT = 'mithusdt',
  MATICBNB = 'maticbnb',
  MATICBTC = 'maticbtc',
  MATICUSDT = 'maticusdt',
  ATOMBNB = 'atombnb',
  ATOMBTC = 'atombtc',
  ATOMUSDT = 'atomusdt',
  ATOMUSDC = 'atomusdc',
  ATOMTUSD = 'atomtusd',
  BATUSDC = 'batusdc',
  BATPAX = 'batpax',
  BATTUSD = 'battusd',
  PHBBNB = 'phbbnb',
  PHBBTC = 'phbbtc',
  PHBTUSD = 'phbtusd',
  TFUELBNB = 'tfuelbnb',
  TFUELBTC = 'tfuelbtc',
  TFUELUSDT = 'tfuelusdt',
  ONEBNB = 'onebnb',
  ONEBTC = 'onebtc',
  ONEUSDT = 'oneusdt',
  ONEUSDC = 'oneusdc',
  FTMBNB = 'ftmbnb',
  FTMBTC = 'ftmbtc',
  FTMUSDT = 'ftmusdt',
  ALGOBNB = 'algobnb',
  ALGOBTC = 'algobtc',
  ALGOUSDT = 'algousdt',
  ALGOTUSD = 'algotusd',
  ALGOPAX = 'algopax',
  GTOUSDT = 'gtousdt',
  ERDBNB = 'erdbnb',
  ERDBTC = 'erdbtc',
  ERDUSDT = 'erdusdt',
  DOGEBNB = 'dogebnb',
  DOGEBTC = 'dogebtc',
  DOGEUSDT = 'dogeusdt',
  DUSKBNB = 'duskbnb',
  DUSKBTC = 'duskbtc',
  DUSKUSDT = 'duskusdt',
  DUSKUSDC = 'duskusdc',
  DUSKPAX = 'duskpax',
  BGBPUSDC = 'bgbpusdc',
  ANKRBNB = 'ankrbnb',
  ANKRBTC = 'ankrbtc',
  ANKRUSDT = 'ankrusdt',
  ONTPAX = 'ontpax',
  WINBNB = 'winbnb',
  WINUSDT = 'winusdt',
  WINUSDC = 'winusdc',
  COSBNB = 'cosbnb',
  COSBTC = 'cosbtc',
  COSUSDT = 'cosusdt',
  NPXSUSDT = 'npxsusdt',
  COCOSBNB = 'cocosbnb',
  COCOSBTC = 'cocosbtc',
  COCOSUSDT = 'cocosusdt',
  MTLUSDT = 'mtlusdt',
  TOMOBNB = 'tomobnb',
  TOMOBTC = 'tomobtc',
  TOMOUSDT = 'tomousdt',
  TOMOUSDC = 'tomousdc',
  PERLBNB = 'perlbnb',
  PERLBTC = 'perlbtc',
  PERLUSDT = 'perlusdt',
  DENTUSDT = 'dentusdt',
  MFTUSDT = 'mftusdt',
  KEYUSDT = 'keyusdt',
  STORMUSDT = 'stormusdt',
  DOCKUSDT = 'dockusdt',
  WANUSDT = 'wanusdt',
  FUNUSDT = 'funusdt',
  CVCUSDT = 'cvcusdt',
  BTTTRX = 'btttrx',
  WINTRX = 'wintrx',
  CHZBNB = 'chzbnb',
  CHZBTC = 'chzbtc',
  CHZUSDT = 'chzusdt',
  BANDBNB = 'bandbnb',
  BANDBTC = 'bandbtc',
  BANDUSDT = 'bandusdt',
  BNBBUSD = 'bnbbusd',
  BTCBUSD = 'btcbusd',
  BUSDUSDT = 'busdusdt',
  BEAMBNB = 'beambnb',
  BEAMBTC = 'beambtc',
  BEAMUSDT = 'beamusdt',
  XTZBNB = 'xtzbnb',
  XTZBTC = 'xtzbtc',
  XTZUSDT = 'xtzusdt',
  RENUSDT = 'renusdt',
  RVNUSDT = 'rvnusdt',
  HCUSDT = 'hcusdt',
  HBARBNB = 'hbarbnb',
  HBARBTC = 'hbarbtc',
  HBARUSDT = 'hbarusdt',
  NKNBNB = 'nknbnb',
  NKNBTC = 'nknbtc',
  NKNUSDT = 'nknusdt',
  XRPBUSD = 'xrpbusd',
  ETHBUSD = 'ethbusd',
  LTCBUSD = 'ltcbusd',
  LINKBUSD = 'linkbusd',
  ETCBUSD = 'etcbusd',
  STXBNB = 'stxbnb',
  STXBTC = 'stxbtc',
  STXUSDT = 'stxusdt',
  KAVABNB = 'kavabnb',
  KAVABTC = 'kavabtc',
  KAVAUSDT = 'kavausdt',
  BUSDNGN = 'busdngn',
  BNBNGN = 'bnbngn',
  BTCNGN = 'btcngn',
  ARPABNB = 'arpabnb',
  ARPABTC = 'arpabtc',
  ARPAUSDT = 'arpausdt',
  TRXBUSD = 'trxbusd',
  EOSBUSD = 'eosbusd',
  IOTXUSDT = 'iotxusdt',
  RLCUSDT = 'rlcusdt',
  MCOUSDT = 'mcousdt',
  XLMBUSD = 'xlmbusd',
  ADABUSD = 'adabusd',
  CTXCBNB = 'ctxcbnb',
  CTXCBTC = 'ctxcbtc',
  CTXCUSDT = 'ctxcusdt',
  BCHBNB = 'bchbnb',
  BCHBTC = 'bchbtc',
  BCHUSDT = 'bchusdt',
  BCHUSDC = 'bchusdc',
  BCHTUSD = 'bchtusd',
  BCHPAX = 'bchpax',
  BCHBUSD = 'bchbusd',
  BTCRUB = 'btcrub',
  ETHRUB = 'ethrub',
  XRPRUB = 'xrprub',
  BNBRUB = 'bnbrub',
  TROYBNB = 'troybnb',
  TROYBTC = 'troybtc',
  TROYUSDT = 'troyusdt',
  BUSDRUB = 'busdrub',
  QTUMBUSD = 'qtumbusd',
  VETBUSD = 'vetbusd',
  VITEBNB = 'vitebnb',
  VITEBTC = 'vitebtc',
  VITEUSDT = 'viteusdt',
  FTTBNB = 'fttbnb',
  FTTBTC = 'fttbtc',
  FTTUSDT = 'fttusdt',
  BTCTRY = 'btctry',
  BNBTRY = 'bnbtry',
  BUSDTRY = 'busdtry',
  ETHTRY = 'ethtry',
  XRPTRY = 'xrptry',
  USDTTRY = 'usdttry',
  USDTRUB = 'usdtrub',
  BTCEUR = 'btceur',
  ETHEUR = 'etheur',
  BNBEUR = 'bnbeur',
  XRPEUR = 'xrpeur',
  EURBUSD = 'eurbusd',
  EURUSDT = 'eurusdt',
  OGNBNB = 'ognbnb',
  OGNBTC = 'ognbtc',
  OGNUSDT = 'ognusdt',
  DREPBNB = 'drepbnb',
  DREPBTC = 'drepbtc',
  DREPUSDT = 'drepusdt',
  BULLUSDT = 'bullusdt',
  BULLBUSD = 'bullbusd',
  BEARUSDT = 'bearusdt',
  BEARBUSD = 'bearbusd',
  ETHBULLUSDT = 'ethbullusdt',
  ETHBULLBUSD = 'ethbullbusd',
  ETHBEARUSDT = 'ethbearusdt',
  ETHBEARBUSD = 'ethbearbusd',
  TCTBNB = 'tctbnb',
  TCTBTC = 'tctbtc',
  TCTUSDT = 'tctusdt',
  WRXBNB = 'wrxbnb',
  WRXBTC = 'wrxbtc',
  WRXUSDT = 'wrxusdt',
  ICXBUSD = 'icxbusd',
  BTSUSDT = 'btsusdt',
  BTSBUSD = 'btsbusd',
  LSKUSDT = 'lskusdt',
  BNTUSDT = 'bntusdt',
  BNTBUSD = 'bntbusd',
  LTOBNB = 'ltobnb',
  LTOBTC = 'ltobtc',
  LTOUSDT = 'ltousdt',
}
