import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PreloadAllModules, Router, RouterModule } from '@angular/router';
import { akitaDevtools, DevtoolsOptions } from '@datorama/akita';
import { TranslocoService } from '@ngneat/transloco';
import * as Sentry from '@sentry/angular';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { HttpErrorInterceptor } from './core/interceptors/http-errors.interceptor';
import { AppInitService } from './core/services/app-init.service';
import { LoaderService } from './core/services/loader.service';
import { ThemeService } from './core/services/theme.service';
import { TranslocoRootModule } from './core/transloco/transloco-root.module';

export function appInit(appInitService: AppInitService) {
  return () => appInitService.init();
}

const akitaConfig: Partial<DevtoolsOptions> = {
  shallow: true,
  maxAge: 100,
  actionsBlacklist: [
    'Mini-ticker',
    'Candle',
    'Aggregate-trade',
    'Server-status',
    'Balance',
    'Server-orders',
  ],
};

function setAkitaDevTools() {
  function checkReduxQueryParameter(): boolean {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.has('redux');
  }

  if (checkReduxQueryParameter()) {
    akitaDevtools(akitaConfig);
  }
}

setAkitaDevTools();

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CoreModule,
    AppRoutingModule,
    RouterModule.forRoot([], {
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'enabled',
      enableTracing: false,
      preloadingStrategy: PreloadAllModules,
    }),
    ToastrModule.forRoot(),
    TranslocoRootModule,
  ],
  providers: [
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        hasBackdrop: false,
        minWidth: 300,
      },
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      deps: [AppInitService, TranslocoService],
      multi: true,
    },
    LoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule,
    private themeService: ThemeService,
    private loaderService: LoaderService,
  ) {
    if (parentModule) {
      throw new Error('AppModule is already loaded. Import only in AppModule');
    }
    this.initializeCoreState();
  }

  private initializeCoreState() {
    this.themeService.init();
    this.loaderService.init();
  }
}
