export function assertNever(): never {
  throw new Error('Type error - never');
}

export const removeUndefined = (obj: any): any => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === 'object') removeUndefined(obj[key]);
    else if (obj[key] === undefined) delete obj[key];
  });
  return obj;
};
