import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import { AuthQuery } from '../state/auth/auth.query';

@Injectable({ providedIn: 'root' })
export class NotAllowedAfterLogin implements CanActivate {
  constructor(
    private router: Router,
    private authQuery: AuthQuery,
  ) {}

  canActivate() {
    return this.authQuery.isAuthenticated$.pipe(
      tap((isAuthenticated) => {
        if (isAuthenticated) {
          this.router.navigateByUrl('/auth');
        }
      }),
      map((isAuth) => !isAuth),
    );
  }
}
