import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { AuthQuery } from '../state/auth/auth.query';

@Injectable({ providedIn: 'root' })
export class NotAllowedBeforeLogin implements CanActivate {
  constructor(
    private router: Router,
    private authQuery: AuthQuery,
  ) {}

  canActivate() {
    return this.authQuery.isAuthenticated$.pipe(
      distinctUntilChanged(),
      tap((isAuthenticated) => {
        if (!isAuthenticated) {
          this.router.navigateByUrl('/login');
        }
      }),
    );
  }
}
