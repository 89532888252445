import { ExchangeOrderDto } from './entities-dto/exchange-order.resp.dto';

export interface IValidateOrderResponse {
  status: ValidationStatus;
  exchangeOrder: ExchangeOrderDto | null;
  warnings?: string[];
  errors?: string[];
}

export enum ValidationStatus {
  VALID = 'VALID',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}
