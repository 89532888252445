import {
  IServerBatchOrderDependencyFilter,
  IServerBatchOrderDependencyFilterForm,
  IServerOrderCandleFilter,
  IServerOrderCandleFilterForm,
  IServerOrderPriceFilter,
  IServerOrderPriceFilterForm,
  IServerOrderWebHookFilter,
  IServerOrderWebHookFilterForm,
  ServerOrderFilterType,
} from './filter.interface';

export interface ITemplateCommonFilter {
  startFromDiff?: number;
  endToDiff?: number;
}

export interface ITemplatePriceCommonFilter extends ITemplateCommonFilter {
  pricePrc: string;
  startFromDiff?: number;
  endToDiff?: number;
}

export interface ITemplateServerOrderPriceFilter
  extends Omit<IServerOrderPriceFilter, 'price' | 'startFrom' | 'endTo'>,
    ITemplatePriceCommonFilter {
  pricePrc: string;
  startFromDiff?: number;
  endToDiff?: number;
}

export interface ITemplateServerOrderPriceFilterForm
  extends Omit<IServerOrderPriceFilterForm, 'price' | 'startFrom' | 'endTo'>,
    ITemplatePriceCommonFilter {
  pricePrc: string;
  startFromDiff?: number;
  endToDiff?: number;
}

export interface ITemplateServerOrderDependencyFilter
  extends Omit<IServerBatchOrderDependencyFilter, 'startFrom' | 'endTo' | 'symbol' | 'orderId'>,
    ITemplateCommonFilter {
  startFromDiff?: number;
  endToDiff?: number;
}

export interface ITemplateServerOrderDependencyFilterForm
  extends Omit<IServerBatchOrderDependencyFilterForm, 'startFrom' | 'endTo' | 'symbol' | 'order'>,
    ITemplateCommonFilter {
  batchOrderIndex: number;
  startFromDiff?: number;
  endToDiff?: number;
}

export interface ITemplateServerOrderCandleFilter
  extends Omit<IServerOrderCandleFilter, 'price' | 'startFrom' | 'endTo'>,
    ITemplatePriceCommonFilter {
  pricePrc: string;
  startFromDiff?: number;
  endToDiff?: number;
}

export interface ITemplateServerOrderCandleFilterForm
  extends Omit<IServerOrderCandleFilterForm, 'price' | 'startFrom' | 'endTo'>,
    ITemplatePriceCommonFilter {
  pricePrc: string;
  startFromDiff?: number;
  endToDiff?: number;
}

export interface ITemplateServerOrderWebHookFilter
  extends Omit<IServerOrderWebHookFilter, 'startFrom' | 'endTo' | 'symbol'>,
    ITemplateCommonFilter {
  startFromDiff?: number;
  endToDiff?: number;
}

export interface ITemplateServerOrderWebHookFilterForm
  extends Omit<IServerOrderWebHookFilterForm, 'startFrom' | 'endTo' | 'symbol'>,
    ITemplateCommonFilter {
  startFromDiff?: number;
  endToDiff?: number;
}

export function isTemplateServerOrderDependencyFilterForm(
  filter: ITemplateServerOrderFilter | ITemplateServerOrderFilterForm,
): filter is ITemplateServerOrderDependencyFilterForm {
  return !!(filter.type === ServerOrderFilterType.BATCH_ORDER_STATUS);
}

export function isTemplateServerOrderCandleFilterForm(
  filter: ITemplateServerOrderFilter | ITemplateServerOrderFilterForm,
): filter is ITemplateServerOrderCandleFilterForm {
  return filter.type === ServerOrderFilterType.CANDLE;
}

export function isTemplateServerOrderPriceFilter(
  filter: ITemplateServerOrderFilter | ITemplateServerOrderFilterForm,
): filter is ITemplateServerOrderPriceFilterForm {
  return filter.type === ServerOrderFilterType.PRICE;
}

export function isTemplateServerOrderWebhookFilter(
  filter: ITemplateServerOrderFilter | ITemplateServerOrderFilterForm,
): filter is ITemplateServerOrderWebHookFilterForm {
  return filter.type === ServerOrderFilterType.WEB_HOOK;
}

export type ITemplateServerOrderFilter =
  | ITemplateServerOrderPriceFilter
  | ITemplateServerOrderDependencyFilter
  | ITemplateServerOrderCandleFilter
  | ITemplateServerOrderWebHookFilter;

export type ITemplateServerOrderFilterForm =
  | ITemplateServerOrderPriceFilter
  | ITemplateServerOrderDependencyFilterForm
  | ITemplateServerOrderCandleFilterForm
  | ITemplateServerOrderWebHookFilterForm;
