import { Injectable } from '@angular/core';
import { ILoginDto, ILoginResponseDto } from '@escript/core-models';
import { Observable } from 'rxjs';
import { ApiService } from '../../services/api.service';

@Injectable({ providedIn: 'root' })
export class AuthApiService {
  constructor(private apiService: ApiService) {}

  login(credentials: ILoginDto): Observable<ILoginResponseDto | null> {
    return this.apiService.post('/login', credentials);
  }
  logout(): Observable<void> {
    return this.apiService.get('/logout');
  }
}
