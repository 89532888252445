import { Injectable } from '@angular/core';
import { safeParse, safeStringify } from '@trader/utils-common';

const APP_PREFIX = 'TRADER-';
function makePrefixedKey(key: string): string {
  return `${APP_PREFIX}${key}`;
}

@Injectable({ providedIn: 'root' })
export class StorageService {
  static loadInitialState() {
    return Object.keys(localStorage).reduce((state: any, storageKey) => {
      if (storageKey.includes(APP_PREFIX)) {
        const stateKeys = storageKey
          .replace(APP_PREFIX, '')
          .toLowerCase()
          .split('.')
          .map((key) =>
            key
              .split('-')
              .map((token, index) =>
                index === 0 ? token : token.charAt(0).toUpperCase() + token.slice(1),
              )
              .join(''),
          );
        let currentStateRef = state;
        stateKeys.forEach((key, index) => {
          if (index === stateKeys.length - 1) {
            currentStateRef[key] = JSON.parse(localStorage.getItem(storageKey) || '');
            return;
          }
          currentStateRef[key] = currentStateRef[key] || {};
          currentStateRef = currentStateRef[key];
        });
      }
      return state;
    }, {});
  }

  public testLocalStorage() {
    const testValue = 'testValue';
    const testKey = 'testKey';
    let retrievedValue: string;
    const errorMessage = 'localStorage did not return expected value';

    this.setInStorage(testKey, testValue);
    // eslint-disable-next-line prefer-const
    retrievedValue = this.getFromStorage(testKey);
    this.removeFormStorage(testKey);

    if (retrievedValue !== testValue) {
      throw new Error(errorMessage);
    }
  }

  public get(key: string): any {
    return this.getFromStorage(key);
  }

  public clear(): void {
    localStorage.clear();
  }

  private setInStorage(key: string, value: any): boolean {
    if (!key) {
      console.error("Key can't be null");
      return false;
    }

    const prefixedKey = makePrefixedKey(key);
    const valueString = safeStringify(value);

    if (!valueString) {
      console.error("Value can't be null");
      return false;
    }

    localStorage.setItem(prefixedKey, valueString);
    return localStorage.getItem(prefixedKey) === valueString;
  }

  private getFromStorage(key: string): any {
    if (!key) {
      console.error("Key can't be null");
      return;
    }
    const prefixedKey = makePrefixedKey(key);
    const storedString = localStorage.getItem(prefixedKey);
    return safeParse(storedString);
  }

  private removeFormStorage(key: string): boolean {
    if (!key) {
      console.error("Key can't be null");
      return false;
    }
    const prefixedKey = makePrefixedKey(key);
    localStorage.removeItem(prefixedKey);
    return !localStorage.getItem(prefixedKey);
  }
}
