import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import {
  IUserExchangeSettingsProfileDto,
  IUserProfileDto,
  UserGroupEnum,
} from '@escript/core-models';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AuthState, AuthStore } from './auth.store';

@Injectable({ providedIn: 'root' })
export class AuthQuery extends Query<AuthState> {
  constructor(protected store: AuthStore) {
    super(store);
  }

  isAuthenticated$: Observable<boolean> = this.select((state) => !!state?.meta?.token);
  meId$: Observable<number | undefined> = this.select((state) => state?.profile?.id);
  profile$: Observable<IUserProfileDto> = this.select((state) => state?.profile).pipe(
    filter((x): x is IUserProfileDto => !!x),
  );

  readonly exchangeUserSettings$: Observable<IUserExchangeSettingsProfileDto[]> =
    this.profile$.pipe(map((profile) => profile.settings.exchangeSettings));

  userGroup$ = this.select((state) => state.profile?.groupId);

  isAdmin$ = this.userGroup$.pipe(map((group) => group === UserGroupEnum.ADMIN));

  get profile(): IUserProfileDto {
    return this.getValue().profile;
  }

  get isAuthenticated(): boolean {
    return !!this.getValue().meta?.token;
  }

  getMeId(): number {
    return this.getValue()?.profile?.id as any;
  }

  getIsAdmin(): boolean {
    return this.getValue()?.profile?.groupId === UserGroupEnum.ADMIN;
  }

  getDefaultConnectedExchangeId(): number {
    const profile = this.getValue().profile;
    if (!profile) {
      return 0;
    }
    const connectedUserSettings = profile.settings.exchangeSettings.find((s) => !!s.connected);
    return connectedUserSettings?.exchangeId || 0;
  }

  selectMeId(): Observable<number> {
    return this.select((state) => state?.profile?.id) as any;
  }
}
