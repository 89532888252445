export enum BatchServerOrderStatus {
  NEW = 'NEW',
  PROCESSING = 'PROCESSING', // If server order is being placed on the exchange
  PROCESSING_WITH_ERRORS = 'PROCESSING_WITH_ERRORS', // If server order is being placed on the exchange with any error
  PARTIALLY_FILLED = 'PARTIALLY_FILLED',
  CANCELED = 'CANCELED',
  EXPIRED = 'EXPIRED',
  FILLED_WITH_ERRORS = 'FILLED_WITH_ERRORS',
  FILLED = 'FILLED',
  SERVER_REJECTED_ERROR = 'SERVER_REJECTED_ERROR',
  PROCESSED_WITH_ERRORS = 'PROCESSED_WITH_ERRORS',
}
