import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { environment as env } from '../environments/environment';
import { StorageService } from './services/storage.service';

@Component({
  selector: 'trader-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isProd = env.production;
  envName = env.envName;
  version = env.versions.app;
  constructor(
    private storageService: StorageService,
    @Inject(DOCUMENT) private document: Document,
    private http: HttpClient,
  ) {}

  ngOnInit(): void {
    this.storageService.testLocalStorage();
    this.printAppInfo();
  }

  printAppInfo() {
    this.http
      .get('/assets/manifest.json')
      .toPromise()
      .then((manifest: any) => {
        let message = `IFElse Finance
    Environment: ${this.envName}
    Version: ${this.version}
    `;
        if (manifest) {
          message =
            message +
            `built: ${manifest.appBuildDate}
    commit: ${manifest.gitCommitHash}
    git tag: ${manifest.gitCommitTag}
      `;
        }
        // tslint:disable-next-line:no-console
        console.log(message);
      });
  }
}
