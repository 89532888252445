import { AssetStatus } from './../dto/entities-dto/exchange-asset-entity.dto';
/* eslint-disable @typescript-eslint/no-empty-function */
import { CommonStreamTypes } from '../base-symbol';
import {
  IExchangeAsset,
  INotificationParamsDto,
  IServerOrderEntityDto,
  IUserNotificationParamsDto,
} from '../dto/entities-dto';
import { BaseSeverStreamRespDto, CandleStreamDto } from '../dto/exchange-stream-messages/response';
import { HeartBeatReportDto, UserNotificationRespDto } from '../dto/server-steram-messages';
import { TradeSymbol } from '../trade-symbol.enum';

export abstract class Message {
  public time = Date.now();
  public type: BrokerMessageType;

  constructor(public srvId: string) {}
}

export enum BrokerMessageType {
  HEART_BEAT = 'HEART_BEAT',
  HEART_BEAT_REPORT = 'HEART_BEAT_REPORT',
  BASE_SIGNAL = 'BASE_SIGNAL',
  BASE_SIGNAL_FILTERED = 'BASE_SIGNAL_FILTERED',
  CHANGE_OBSERVED_TOPICS = 'CHANGE_OBSERVED_TOPICS',
  UPDATE_ASSET = 'UPDATE_ASSET',
  UPDATE_ASSET_LIST = 'UPDATE_ASSET_LIST',
  UPDATE_ALL_ASSET_DETAILS = 'UPDATE_ALL_ASSET_DETAILS',
  UPDATE_EXCHANGE_INFO_LIST = 'UPDATE_EXCHANGE_INFO_LIST',
  UPDATE_USERS_BALANCE_LIST = 'UPDATE_USERS_BALANCE_LIST',
  EXECUTE_ORDER = 'EXECUTE_ORDER',
  NOTIFY_USER = 'NOTIFY_USER',
  NOTIFY_ALL_USERS = 'NOTIFY_ALL_USER',
  NOTIFY_ALL_ADMINS = 'NOTIFY_ALL_ADMINS',
}

export class BaseSignalMessage extends Message {
  public type = BrokerMessageType.BASE_SIGNAL;

  constructor(
    public srvId: string,
    public payload: BaseSeverStreamRespDto,
  ) {
    super(srvId);
  }
}

export class BaseSignalFilteredMessage extends Message {
  public type = BrokerMessageType.BASE_SIGNAL_FILTERED;
  constructor(
    public srvId: string,
    public payload: BaseSeverStreamRespDto,
  ) {
    super(srvId);
  }
}

export class ChangeObservedTopicsMessage extends Message {
  public type = BrokerMessageType.CHANGE_OBSERVED_TOPICS;

  constructor(
    public srvId: string,
    public exchangeId: number,
    public payload: ChangeObservedTopicPayload[],
  ) {
    super(srvId);
  }
}

export class ChangeObservedTopicPayload {
  type: CommonStreamTypes;
  symbol: TradeSymbol;
  contractId?: string;
}

export class UpdateAssetListMessage extends Message {
  public type = BrokerMessageType.UPDATE_ASSET_LIST;
  constructor(public srvId: string) {
    super(srvId);
  }
}

export class UpdateAllAssetDetailsMessage extends Message {
  public type = BrokerMessageType.UPDATE_ALL_ASSET_DETAILS;
  constructor(
    public srvId: string,
    public payload: UpdateAllAssetDetailsMessagePayload,
  ) {
    super(srvId);
  }
}

export class UpdateAllAssetDetailsMessagePayload {
  fromDate: string;
  externalLastUpdated?: string;
  status?: AssetStatus;
  hasDetails?: boolean;
}

export class UpdateExchangeInfoListMessage extends Message {
  public type = BrokerMessageType.UPDATE_EXCHANGE_INFO_LIST;
  constructor(
    public srvId: string,
    public exchangeId: number,
  ) {
    super(srvId);
  }
}

export class AssetToUpdateMessage extends Message {
  public type = BrokerMessageType.UPDATE_ASSET;
  constructor(
    public srvId: string,
    public payload: IExchangeAsset,
  ) {
    super(srvId);
  }
}

export class ExecuteOrderMessage extends Message {
  public type = BrokerMessageType.EXECUTE_ORDER;
  constructor(
    public srvId: string,
    public payload: { order: IServerOrderEntityDto; candle: CandleStreamDto },
  ) {
    super(srvId);
  }
}

export class NotifyAllUsersMessage extends Message {
  public type = BrokerMessageType.NOTIFY_ALL_USERS;
  constructor(
    public srvId: string,
    public payload: INotificationParamsDto,
  ) {
    super(srvId);
  }
}

export class NotifyAllAdminsMessage extends Message {
  public type = BrokerMessageType.NOTIFY_ALL_ADMINS;
  constructor(
    public srvId: string,
    public payload: INotificationParamsDto,
  ) {
    super(srvId);
  }
}

export class NotifyUserMessage extends Message {
  public type = BrokerMessageType.NOTIFY_USER;
  constructor(
    public srvId: string,
    public payload: IUserNotificationParamsDto,
  ) {
    super(srvId);
  }
}
export class UINotifyUserMessage extends Message {
  public type = BrokerMessageType.NOTIFY_USER;
  constructor(
    public srvId: string,
    public payload: UserNotificationRespDto,
  ) {
    super(srvId);
  }
}

export class HeartBeatMessage extends Message {
  public type = BrokerMessageType.HEART_BEAT;
  constructor(
    public srvId: string,
    public time: number,
  ) {
    super(srvId);
  }
}

export class HeartBeatRapportMessage extends Message {
  public type = BrokerMessageType.HEART_BEAT_REPORT;
  constructor(
    public srvId: string,
    public payload: HeartBeatReportDto,
  ) {
    super(srvId);
  }
}
