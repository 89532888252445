import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { delay } from 'helpful-decorators';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { ThemeService } from './theme.service';

@Injectable({ providedIn: 'root' })
export class LoaderService {
  private renderer: Renderer2;
  loaderSub$ = new BehaviorSubject<boolean>(true);
  loader$ = this.loaderSub$.asObservable();
  constructor(
    private themeService: ThemeService,
    @Inject(DOCUMENT) private document: Document,
    rendererFactory: RendererFactory2,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  init() {
    this.addLoadedClass();
    this.subscribeToLoader();
    this.subscribeToTheme();
  }

  @delay(1000)
  private addLoadedClass() {
    this.loaderSub$.next(false);
  }

  private subscribeToLoader() {
    return this.loader$.subscribe((showLoader) => {
      if (showLoader) {
        this.renderer.removeClass(this.document.body, 'loaded');
      } else {
        this.renderer.addClass(this.document.body, 'loaded');
      }
    });
  }

  private subscribeToTheme() {
    return this.themeService.theme$.pipe(distinctUntilChanged()).subscribe((theme) => {
      this.renderer.removeClass(this.document.body, 'black-theme');
      this.renderer.removeClass(this.document.body, 'default-theme');
      this.renderer.addClass(this.document.body, theme);
    });
  }
}
