import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotAllowedAfterLogin } from './core/guards/not-allowed-after-login.guard';
import { NotAllowedBeforeLogin } from './core/guards/not-allowed-before-login.guard';

const routes: Routes = [
  {
    path: 'login',
    canActivate: [NotAllowedAfterLogin],
    loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'auth',
    canActivate: [NotAllowedBeforeLogin],
    loadChildren: () =>
      import('./pages/after-login/after-login.module').then((m) => m.AfterLoginModule),
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'auth',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
