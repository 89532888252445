import { TradeSymbol } from './trade-symbol.enum';

export interface ExchangeUIConfig {
  fields: string[];
  type: ExchangeUiType;
  default: {
    baseAssetId: string;
    quoteAssetId: string;
    tradeSymbol: TradeSymbol;
  };
}

export enum ExchangeUiType {
  CEX = 'CEX',
  DEX = 'DEX',
}
