import { Injectable, NgZone } from '@angular/core';
import { map, mergeMapTo } from 'rxjs/operators';
import { SettingsState } from '../../types/settings.model';
import { SettingsQuery } from '../state/settings/settings.query';
import { SettingsStore } from '../state/settings/settings.store';

@Injectable({ providedIn: 'root' })
export class ThemeService {
  hour = 0;

  constructor(
    private ngZone: NgZone,
    private settingStore: SettingsStore,
    private settingsQuery: SettingsQuery,
  ) {}

  theme$ = this.settingsQuery.theme$.pipe(map((el) => el.toLowerCase()));

  init() {
    this.updateHour();
    this.changeThemeInNightMode();
  }

  private updateHour() {
    this.ngZone.runOutsideAngular(() =>
      setInterval(() => {
        const hour = new Date().getHours();
        if (hour !== this.hour) {
          this.ngZone.run(() => this.settingStore.update({ hour }));
        }
      }, 60000),
    );
  }

  private changeThemeInNightMode() {
    this.settingsQuery
      .select('hour')
      .pipe(mergeMapTo(this.settingsQuery.select()))
      .subscribe((state) => {
        if (state.hour !== this.hour && state.autoNightMode) {
          this.hour = new Date().getHours();
          this.settingStore.update({ hour: this.hour });
          if (this.isNight(state)) {
            this.settingStore.setNightModeTheme();
          } else {
            this.settingStore.setDefaultTheme();
          }
        }
      });
  }

  private isNight(state: SettingsState): boolean {
    if (state.hour > 21 || state.hour < 7) {
      return true;
    }
    return false;
  }
}
