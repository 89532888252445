import { Big } from 'big.js';

export function stepSizeFlor(num: any, digits: number, base = 10): number {
  if (typeof num === 'string') {
    num = Number(num);
    if (isNaN(num)) {
      return 0;
    }
  }
  if (typeof num !== 'number') {
    return 0;
  }
  const pow: number = Math.pow(base, digits);
  return Math.floor(Number(num) * pow) / pow;
}

export function stepSizeFloor(quantity: string, stepSize: string): string {
  if (!(!!quantity && !!stepSize)) {
    return '0';
  }
  const precision = stepSize.split('.')[1].indexOf('1') + 1;
  const power = Big(10).pow(precision);
  const value = Big(Math.floor(Big(quantity).times(power).toNumber()))
    .div(power)
    .valueOf();
  return toNonExponential(value);
}

export function toPrecision(quantity: string, precision: string | number = 8): string {
  if (Number(precision) < 0) {
    return quantity;
  }
  const power = Big(10).pow(Math.floor(Number(precision)));
  try {
    return Big(Math.floor(Big(quantity).times(power).toNumber()))
      .div(power)
      .valueOf();
  } catch (e) {
    return quantity;
  }
}

export function sumStringNumbers(x: string, y: string): string {
  return Big(x).add(Big(y)).valueOf();
}

export function averageStringNumbers(x: string, y: string): string {
  const decimals = x.split('.')[1];
  const precission = decimals ? decimals.length - 1 : 2;
  return toPrecision(Big(x).add(Big(y)).div(2).valueOf(), precission);
}

export function toNonExponential(value: any) {
  // if value is not a number try to convert it to number
  if (typeof value !== 'number') {
    value = parseFloat(value);

    // after convert, if value is not a number return empty string
    if (isNaN(value)) {
      return value;
    }
  }

  let sign;
  let e;

  // if value is negative, save "-" in sign variable and calculate the absolute value
  if (value < 0) {
    sign = '-';
    value = Math.abs(value);
  } else {
    sign = '';
  }

  // if value is between 0 and 1
  if (value < 1.0) {
    // get e value
    e = parseInt(value.toString().split('e-')[1], 10);

    // if value is exponential convert it to non exponential
    if (e) {
      value *= Math.pow(10, e - 1);
      value = '0.' + new Array(e).join('0') + value.toString().substring(2);
    }
  } else {
    // get e value
    e = parseInt(value.toString().split('e+')[1], 10);

    // if value is exponential convert it to non exponential
    if (e) {
      value /= Math.pow(10, e);
      value += new Array(e + 1).join('0');
    }
  }

  // if value has negative sign, add to it
  return sign + value;
}

export function countDecimals(value: number | string) {
  if (Math.floor(Number(value)) === Number(value)) {
    return 0;
  }
  return value.toString().split('.')[1].length || 0;
}
