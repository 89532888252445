import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { akitaConfig, enableAkitaProdMode, persistState } from '@datorama/akita';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import localForage from 'localforage';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  enableAkitaProdMode();
}
akitaConfig({
  resettable: true,
});

localForage.config({
  driver: localForage.INDEXEDDB,
  name: 'ifelse',
  storeName: 'ifelse',
});

persistState({
  include: ['auth'],
});

persistState({
  include: [
    'settings',
    'favoriteAssets',
    'trade',
    'user-settings',
    'symbol',
    'quoteAsset',
    'asset',
    'order-tags',
    'batch-order-tags',
    'batch-template-orders',
  ],
  storage: localForage,
});

if (environment.production) {
  Sentry.init({
    dsn: 'https://cc931fc7ffc84d27a4dc58f4fe3c3f6d@o49691.ingest.sentry.io/5588609',
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      // new Integrations.BrowserTracing({
      //   tracingOrigins: ['localhost', environment.baseUrl],
      //   routingInstrumentation: Sentry.routingInstrumentation,
      // }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

setTimeout(() => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
}, 100);
