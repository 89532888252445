import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslocoModule } from '@ngneat/transloco';
import { ToastrModule } from 'ngx-toastr';
import { AnimationsService } from './animations/animations.service';
import { ROUTE_ANIMATIONS_ELEMENTS, routeAnimations } from './animations/route.animations';
import { TitleService } from './title/title.service';

export { TitleService, routeAnimations, ROUTE_ANIMATIONS_ELEMENTS, AnimationsService };

export const ANGULAR_MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  HttpClientModule,
  RouterModule,
];

export const ANGULAR_MATERIAL_MODULES = [
  MatInputModule,
  MatButtonModule,
  MatCardModule,
  MatFormFieldModule,
  MatIconModule,
  MatBottomSheetModule,
  MatTooltipModule,
];

export const OTHER_CORE_LIBS_MODULES = [
  TranslocoModule,
  FlexLayoutModule,
  ToastrModule,
  FontAwesomeModule,
];

@NgModule({
  imports: [...ANGULAR_MODULES, ...ANGULAR_MATERIAL_MODULES, ...OTHER_CORE_LIBS_MODULES],
  exports: [...ANGULAR_MODULES, ...ANGULAR_MATERIAL_MODULES, ...OTHER_CORE_LIBS_MODULES],
})
export class CoreModule {}
