export type ExchangeFilter =
  | ExchangePriceFilter
  | ExchangePercentFiler
  | ExchangeLotSizeFilter
  | ExchangeMinNotionalFilter
  | ExchangeIcebergParts
  | ExchangeMarketLotSize
  | ExchangeMaxNumAlgoOrdersFilter
  | ExchangeUnknownSymbolOrdersFilter;

export enum FilterType {
  PRICE_FILTER = 'PRICE_FILTER',
  PERCENT_PRICE = 'PERCENT_PRICE',
  LOT_SIZE = 'LOT_SIZE',
  MIN_NOTIONAL = 'MIN_NOTIONAL',
  ICEBERG_PARTS = 'ICEBERG_PARTS',
  MARKET_LOT_SIZE = 'MARKET_LOT_SIZE',
  MAX_NUM_ALGO_ORDERS = 'MAX_NUM_ALGO_ORDERS',
  UNKNOWN_SYMBOL = 'UNKNOWN_SYMBOL',
}

export interface ExchangePriceFilter {
  filterType: FilterType.PRICE_FILTER;
  minPrice: string;
  maxPrice: string;
  tickSize: string;
}

export interface ExchangePercentFiler {
  filterType: FilterType.PERCENT_PRICE;
  multiplierUp: string;
  multiplierDown: string;
  avgPriceMins: number;
}

export interface ExchangeLotSizeFilter {
  filterType: FilterType.LOT_SIZE;
  minQty: string;
  maxQty: string;
  stepSize: string;
}

export interface ExchangeMinNotionalFilter {
  filterType: FilterType.MIN_NOTIONAL;
  minNotional: string;
  applyToMarket: boolean;
  avgPriceMins: number;
}

export interface ExchangeIcebergParts {
  filterType: FilterType.ICEBERG_PARTS;
  limit: number;
}

export interface ExchangeMarketLotSize {
  filterType: FilterType.MARKET_LOT_SIZE;
  minQty: string;
  maxQty: string;
  stepSize: string;
}
export interface ExchangeMaxNumAlgoOrdersFilter {
  filterType: FilterType.MAX_NUM_ALGO_ORDERS;
  maxNumAlgoOrders: number;
}
export interface ExchangeUnknownSymbolOrdersFilter {
  filterType: FilterType.UNKNOWN_SYMBOL;
  message: string;
}
