import { CandleInterval, CandlePart, CandleType } from './candle-interval.enum';
import { IServerOrderEntityDto, ServerOrderStatus } from './dto';
import { TradeSymbol } from './trade-symbol.enum';

export enum ServerOrderFilterType {
  PRICE = 'PRICE',
  ORDER_STATUS = 'ORDER_STATUS',
  CANDLE = 'CANDLE',
  WEB_HOOK = 'WEB_HOOK',
  BATCH_ORDER_STATUS = 'BATCH_ORDER_STATUS',
}

export enum FilterNumberComparison {
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_OR_EQUAL = 'LESS_THAN_OR_EQUAL',
  MORE_THAN = 'MORE_THAN',
  MORE_THAN_OR_EQUAL = 'MORE_THAN_OR_EQUAL',
}

export enum FilterMode {
  OR = 'OR',
  AND = 'AND',
}

export enum FilterBooleanComparison {
  IS = 'IS',
  IS_NOT = 'IS_NOT',
}

export enum ServerOrderFilterStatus {
  PRISTINE = 'PRISTINE',
  VALID = 'VALID',
  INVALID = 'INVALID',
}

export interface IServerOrderPriceFilter {
  type: ServerOrderFilterType.PRICE;
  price: string;
  symbol: TradeSymbol;
  mode: FilterMode;
  filterStatus: ServerOrderFilterStatus;
  comparison: FilterNumberComparison;
  startFrom: Date | null;
  endTo: Date | null;
}

export interface IServerOrderPriceFilterForm {
  type: ServerOrderFilterType.PRICE;
  price: string;
  symbol: TradeSymbol;
  filterStatus: ServerOrderFilterStatus;
  mode: FilterMode;
  comparison: FilterNumberComparison;
  pricePrc: string;
  startFrom: Date | null;
  endTo: Date | null;
}

export interface IServerOrderDependencyFilter {
  type: ServerOrderFilterType.ORDER_STATUS;
  status: ServerOrderStatus;
  orderId: number;
  mode: FilterMode;
  filterStatus: ServerOrderFilterStatus;
  comparison: FilterBooleanComparison;
  startFrom: Date | null;
  endTo: Date | null;
}

export interface IServerOrderDependencyFilterForm {
  type: ServerOrderFilterType.ORDER_STATUS;
  status: ServerOrderStatus;
  filterStatus: ServerOrderFilterStatus;
  mode: FilterMode;
  comparison: FilterBooleanComparison;
  order: IServerOrderEntityDto;
  startFrom: Date | null;
  endTo: Date | null;
}

export interface IServerBatchOrderDependencyFilter
  extends Omit<IServerOrderDependencyFilter, 'type' | 'orderId'> {
  type: ServerOrderFilterType.BATCH_ORDER_STATUS;
  batchOrderIndex: number;
}

export interface IServerBatchOrderDependencyFilterForm
  extends Omit<IServerOrderDependencyFilterForm, 'type' | 'orderId'> {
  type: ServerOrderFilterType.BATCH_ORDER_STATUS;
  batchOrderIndex: number;
}

export interface IServerOrderCandleFilter {
  type: ServerOrderFilterType.CANDLE;
  symbol: TradeSymbol;
  mode: FilterMode;
  candleType: CandleType;
  interval: CandleInterval;
  candlePart: CandlePart;
  filterStatus: ServerOrderFilterStatus;
  comparison: FilterNumberComparison;
  price: string;
  startFrom: Date | null;
  endTo: Date | null;
}

export interface IServerOrderCandleFilterForm {
  type: ServerOrderFilterType.CANDLE;
  symbol: TradeSymbol;
  mode: FilterMode;
  filterStatus: ServerOrderFilterStatus;
  interval: CandleInterval;
  candlePart: CandlePart;
  candleType: CandleType;
  comparison: FilterNumberComparison;
  price: string;
  pricePrc: string;
  startFrom: Date | null;
  endTo: Date | null;
}

export interface IServerOrderWebHookFilter {
  type: ServerOrderFilterType.WEB_HOOK;
  mode: FilterMode;
  filterStatus: ServerOrderFilterStatus;
  message: string;
  startFrom: Date | null;
  endTo: Date | null;
}

export interface IServerOrderWebHookFilterForm {
  type: ServerOrderFilterType.WEB_HOOK;
  mode: FilterMode;
  filterStatus: ServerOrderFilterStatus;
  message: string;
  startFrom: Date | null;
  endTo: Date | null;
}

export function isServerOrderDependencyFilterForm(
  filter: IServerOrderFilter | IServerOrderFilterForm,
): filter is IServerOrderDependencyFilterForm {
  return !!(
    filter.type === ServerOrderFilterType.ORDER_STATUS &&
    (filter as IServerOrderDependencyFilterForm).order
  );
}

export function isServerOrderCandleFilterForm(
  filter: IServerOrderFilter | IServerOrderFilterForm,
): filter is IServerOrderCandleFilterForm {
  return filter.type === ServerOrderFilterType.CANDLE;
}

export function isServerOrderPriceFilterForm(
  filter: IServerOrderFilter | IServerOrderFilterForm,
): filter is IServerOrderPriceFilterForm {
  return filter.type === ServerOrderFilterType.PRICE;
}

export type IServerOrderFilter =
  | IServerOrderPriceFilter
  | IServerOrderDependencyFilter
  | IServerOrderCandleFilter
  | IServerOrderWebHookFilter
  | IServerBatchOrderDependencyFilter;
export type IServerOrderFilterForm =
  | IServerOrderPriceFilter
  | IServerOrderDependencyFilterForm
  | IServerOrderCandleFilterForm
  | IServerOrderWebHookFilterForm
  | IServerBatchOrderDependencyFilterForm;
