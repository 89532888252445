import {
  AckMessageStreamDto,
  ErrorStreamDto,
  PongStreamDto,
  SubscribedMessageStreamDto,
  UnsubscribedMessageStreamDto,
  UserAccountInfoStreamRespDto,
  UserBalanceDeltaUpdateStreamRespDto,
  UserBalanceUpdateStreamRespDto,
  UserOcoOrderUpdateStreamRespDto,
  UserOrderUpdateStreamRespDto,
  UserUnknownOrEmptyStreamDto,
} from './dto/exchange-stream-messages/response';

export enum MessageType {
  LIST_SUBSCRIPTIONS = 'LIST_SUBSCRIPTIONS',
  PING = 'PING',
  PONG = 'PONG',
}

export enum UserNotificationMessageType {
  GET_SERVER_NOTIFICATION = 'GET_SERVER_NOTIFICATION',
  SERVER_NOTIFICATION = 'SERVER_NOTIFICATION',
}

export enum UserStreamFromExchangeMessageType {
  GET_SYMBOL_TRADING_INFO = 'USER_STREAM_EXCHANGE_GET_SYMBOL_TRADING_INFO',
  GET_MINI_TICKERS = 'USER_STREAM_EXCHANGE_GET_FAVOURITES_SYMBOLS_TICKERS',
  GET_USER_TRADING_INFO = 'USER_STREAM_EXCHANGE_GET_USER_TRADING_INFO',
  BALANCE_DELTA_UPDATE = 'USER_STREAM_EXCHANGE_BALANCE_DELTA_UPDATE',
  USER_ACCOUNT_UPDATE = 'USER_STREAM_EXCHANGE_ACCOUNT_UPDATE',
  USER_ACCOUNT_BALANCE_UPDATE = 'USER_STREAM_EXCHANGE_ACCOUNT_BALANCE_UPDATE',
  ORDER_UPDATE = 'USER_STREAM_EXCHANGE_ORDER_UPDATE',
  OCO_ORDER_UPDATE = 'USER_STREAM_EXCHANGE_OCO_ORDER_UPDATE',
  USER_UNKNOWN_OR_EMPTY = 'USER_STREAM_EXCHANGE_UNKNOWN_OR_EMPTY',
}

export enum PublicStreamFromExchangeMessageType {
  AGGREGATE_TRADE_DATA = 'PUBLIC_STREAM_EXCHANGE_AGGREGATE_TRADE_DATA',
  ORDER_BOOK_UPDATE = 'PUBLIC_STREAM_EXCHANGE_ORDER_BOOK_UPDATE',
  ORDER_BOOK = 'PUBLIC_STREAM_EXCHANGE_ORDER_BOOK',
  TICKER_DATA = 'PUBLIC_STREAM_EXCHANGE_TICKER_DATA',
  MINI_TICKER_DATA = 'PUBLIC_STREAM_EXCHANGE_MINI_TICKER_DATA',
  CANDLE = 'PUBLIC_STREAM_EXCHANGE_CANDLE',
  UNKNOWN_OR_EMPTY = 'PUBLIC_STREAM_EXCHANGE_UNKNOWN_OR_EMPTY',
  GET_REST_API_STATUS = 'PUBLIC_STREAM_EXCHANGE_GET_REST_API_STATUS',
  CHANGED_SUBSCRIPTION = 'PUBLIC_STREAM_EXCHANGE_CHANGED_SUBSCRIPTION',
  UNSUBSCRIBED = 'PUBLIC_STREAM_EXCHANGE_UNSUBSCRIBED',
  LIST_SUBSCRIPTIONS = 'PUBLIC_STREAM_EXCHANGE_LIST_SUBSCRIPTIONS',
  SUBSCRIPTIONS_INFO = 'PUBLIC_STREAM_EXCHANGE_SUBSCRIPTIONS_INFO',
  GET_SERVER_HEART_BEAT_REPORT = 'PUBLIC_STREAM_EXCHANGE_GET_SERVER_HEART_BEAT_REPORT',
  SUBSCRIBED = 'PUBLIC_STREAM_EXCHANGE_SUBSCRIBED',
  ACK = 'EXCHANGE_ACK',
  PONG = 'PUBLIC_STREAM_EXCHANGE_PONG',
  ERROR = 'PUBLIC_STREAM_EXCHANGE_ERROR',
}

export interface StreamMeta {
  exchangeId: number;
}

export interface UserStreamMeta extends StreamMeta {
  userId: number;
}

export interface StreamMessage {
  type:
    | MessageType
    | UserStreamFromExchangeMessageType
    | PublicStreamFromExchangeMessageType
    | UserNotificationMessageType;
  meta: StreamMeta | UserStreamMeta;
  payload: any;
}

export type UserStreamMessage =
  | UserUnknownOrEmptyStreamDto
  | PongStreamDto
  | UnsubscribedMessageStreamDto
  | ErrorStreamDto
  | SubscribedMessageStreamDto
  | AckMessageStreamDto
  | UserAccountInfoStreamRespDto
  | UserBalanceDeltaUpdateStreamRespDto
  | UserBalanceUpdateStreamRespDto
  | UserOcoOrderUpdateStreamRespDto
  | UserOrderUpdateStreamRespDto;
