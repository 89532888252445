import { IPlaceServerOrderBodyDto } from './dto';
import { IServerOrderFilter } from './filter.interface';
import { OrderSide } from './order-side.enum';
import { ServerOrderType } from './server-order-type.enum';
import { TradeSymbol } from './trade-symbol.enum';

class ServerMarketOrderCommon {
  id?: number;
  type: ServerOrderType.MARKET;
  exchangeId: number;
  symbol: TradeSymbol;
  side: OrderSide;
  reason?: string;
  filters?: IServerOrderFilter[];
  baseAssetId: string;
  quoteAssetId: string;
  tagIds?: number[];
  batchOrderIndex?: number;
}

export class ServerMarketOrderQuoteQuantity extends ServerMarketOrderCommon {
  quoteOrderQty: string;
}
export class ServerMarketOrderBaseQuantity extends ServerMarketOrderCommon {
  quantity: string;
}
export class ServerMarketOrderPrcQuantity extends ServerMarketOrderCommon {
  prcQuantity: string;
}

export class ServerLimitOrder {
  id?: number;
  type: ServerOrderType.LIMIT;
  exchangeId: number;
  symbol: TradeSymbol;
  side: OrderSide;
  quantity: string;
  price: string;
  reason?: string;
  filters?: IServerOrderFilter[];
  quoteAssetId: string;
  baseAssetId: string;
  tagIds?: number[];
  batchOrderIndex?: number;
}
export type ServerMarketOrder =
  | ServerMarketOrderQuoteQuantity
  | ServerMarketOrderBaseQuantity
  | ServerMarketOrderPrcQuantity;
export type ServerOrder = ServerMarketOrder | ServerLimitOrder;

export function isServerLimitOrder(order: ServerOrder): order is ServerLimitOrder {
  return order.type === ServerOrderType.LIMIT;
}

export function isServerMarketOrder(order: ServerOrder): order is ServerMarketOrder {
  return order.type === ServerOrderType.MARKET;
}

export function isServerMarketOrderQuoteQuantity(
  order: ServerOrder,
): order is ServerMarketOrderQuoteQuantity {
  return (
    order.type === ServerOrderType.MARKET &&
    !!(order as ServerMarketOrderQuoteQuantity).quoteOrderQty
  );
}

export function isServerMarketOrderPrc(
  order: ServerOrder | IPlaceServerOrderBodyDto,
): order is ServerMarketOrderPrcQuantity {
  return (
    order.type === ServerOrderType.MARKET && !!(order as ServerMarketOrderPrcQuantity).prcQuantity
  );
}

export function isServerMarketOrderBaseQuantity(
  order: ServerOrder,
): order is ServerMarketOrderBaseQuantity {
  return (
    order.type === ServerOrderType.MARKET && !!(order as ServerMarketOrderBaseQuantity).quantity
  );
}
