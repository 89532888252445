<div class="app-wrapper">
  <router-outlet></router-outlet>
</div>
<div class="shape-mask mask">
  <span class="shape1 header-shape">
    <img src="/assets/images/shape/header5_shape_1.png" alt="" />
  </span>
  <span class="shape2 header-shape">
    <img src="/assets/images/shape/header5_shape_2.png" alt="" />
  </span>
  <span class="shape3 header-shape">
    <img src="/assets/images/shape/header5_shape_3.png" alt="" />
  </span>
</div>
