import { IUserEntityDto } from './user-entity.dto';

export class UserBalanceEntityDto {
  id: number;
  createdDate: Date;
  asset: string;
  free: number;
  locked: number;
  user: IUserEntityDto;
  exchangeId: number;
  assetId: string;
  rateInBtc: string | null;
  rateInUsd: string | null;
}
