import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPaginationOptions } from '@escript/core-models';
import { Observable } from 'rxjs';
import { AuthQuery } from '../core/state/auth/auth.query';

@Injectable({ providedIn: 'root' })
export class ApiService {
  constructor(
    private authQuery: AuthQuery,
    private http: HttpClient,
  ) {}

  static path(url: string): string {
    return `/api/v1${url}`;
  }

  getAccessToken() {
    const token = this.authQuery.getValue()?.meta?.token;
    if (token) {
      return token;
    } else {
      return '';
    }
  }

  get(url: string, options?: GetOptions): Observable<any>;

  get<T>(url: string, options?: GetOptions): Observable<T>;

  get(url: string, options?: GetOptions) {
    return this.http.get(ApiService.path(url), this.generateOptions(options));
  }

  post(url: string, body: any, options = {}): Observable<any> {
    return this.http.post(ApiService.path(url), body, this.generateOptions(options));
  }

  put(url: string, body: any, options?: any): Observable<any> {
    return this.http.put(ApiService.path(url), body, this.generateOptions(options));
  }

  delete(url: string, options?: any): Observable<any> {
    return this.http.delete(ApiService.path(url), this.generateOptions(options));
  }

  patch(url: string, body: any, options?: any): Observable<any> {
    return this.http.patch(ApiService.path(url), body, this.generateOptions(options));
  }

  head(url: string, options?: any): Observable<any> {
    return this.http.head(ApiService.path(url), this.generateOptions(options));
  }

  options(url: string, options?: any): Observable<any> {
    return this.http.options(ApiService.path(url), this.generateOptions(options));
  }

  private generateOptions(options: any = {}) {
    const accessToken = this.getAccessToken();
    if (!options['headers']) {
      options['headers'] = new HttpHeaders();
    }
    if (accessToken) {
      options['headers'] = options['headers'].append('Authorization', 'Bearer ' + accessToken);
    }
    return options;
  }
}

interface GetOptions {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  observe?: 'body';
  params?:
    | HttpParams
    | {
        [param: string]: (string | number) | (string | number)[];
      }
    | IPaginationOptions;
  reportProgress?: boolean;
  responseType?: 'arraybuffer';
  withCredentials?: boolean;
}
