export const safeStringify = (json: any): string => {
  let valueString = '';

  try {
    valueString = JSON.stringify(json);
  } catch (error) {
    console.error(`Error while trying to stringify value for key`);
  }
  return valueString;
};

export const safeParse = (value: string | null): any => {
  if (value === null) {
    return;
  }
  let parsedValue = '';
  try {
    parsedValue = JSON.parse(value);
  } catch (error) {
    console.error('Error while trying to parse string from value');
  }
  return parsedValue;
};
