import { BatchServerOrderStatus } from '../dto/batch-server-order-status.enum';
import { ServerOrderStatus } from '../dto/server-order-status.enum';

export function isOrderStatusErronous(status: ServerOrderStatus): boolean {
  return (
    status === ServerOrderStatus.REJECTED ||
    status === ServerOrderStatus.SERVER_REJECTED_ERROR ||
    status === ServerOrderStatus.SERVER_REJECTED_NO_BALANCE ||
    status === ServerOrderStatus.EXPIRED
  );
}

export function isOrderStatusCancellable(status: ServerOrderStatus): boolean {
  switch (status) {
    case ServerOrderStatus.NEW:
    case ServerOrderStatus.PARTIALLY_FILLED:
    case ServerOrderStatus.PROCESSING:
      return true;
    default:
      return false;
  }
}

export function isBatchOrderStatusInProgress(status: BatchServerOrderStatus): boolean {
  switch (status) {
    case BatchServerOrderStatus.PROCESSING_WITH_ERRORS:
    case BatchServerOrderStatus.PARTIALLY_FILLED:
    case BatchServerOrderStatus.PROCESSING:
      return true;
    default:
      return false;
  }
}

export function isBatchOrderStatusCancellable(status: BatchServerOrderStatus): boolean {
  switch (status) {
    case BatchServerOrderStatus.NEW:
    case BatchServerOrderStatus.PROCESSING_WITH_ERRORS:
    case BatchServerOrderStatus.PARTIALLY_FILLED:
    case BatchServerOrderStatus.PROCESSING:
      return true;
    default:
      return false;
  }
}

export function isOrderStatusToProcess(status: ServerOrderStatus): boolean {
  return status === ServerOrderStatus.NEW || status === ServerOrderStatus.PROCESSING;
}
