import { getTradeSymbolFromCandleSymbol, StreamTopic } from '@escript/core-models';
import { BehaviorSubject, Observable, ObservableInput, Subject } from 'rxjs';
import { concatMap, switchMap } from 'rxjs/operators';

export function webHookMessage(message: string, userId: number): string {
  return `{ "message": "${message}", "userId": ${userId} }`;
}

export function checkIfTrue(value: string | number | undefined | null): boolean {
  if (!value) {
    return false;
  }
  return value === 'TRUE' || value === 'true' || value === 1;
}

export function getUniqTradePairs<T>(symbols: string[]): T[] {
  return Array.from(new Set(symbols)).filter((el) => {
    if (el.length % 2 === 0) {
      // Remove some mistaken pairs like BTCBTC;
      return el.slice(0, el.length / 2) !== el.slice(el.length / 2);
    }
    return true;
  }) as any;
}

export function getUniqCandleSymbols<T>(symbols: StreamTopic[]): T[] {
  return Array.from(new Set(symbols)).filter((el) => {
    const s = getTradeSymbolFromCandleSymbol(el);
    if (s.length % 2 === 0) {
      // Remove some mistaken pairs like BTCBTC;
      return s.slice(0, s.length / 2) !== s.slice(s.length / 2);
    }
    return true;
  }) as any;
}

export function isSame(left: any, right: any): boolean {
  try {
    return JSON.stringify(left) === JSON.stringify(right);
  } catch (e) {
    return false;
  }
}

export function getRandomNumberId(): number {
  return Number((Math.random() * 100000000000000000).toFixed());
}

enum QueueActions {
  RESET = 'RESET',
  ADD = 'ADD',
}

interface QueueCommand {
  action: QueueActions;
  payload?: any;
}

export class ObservableQueue<T> {
  private _commands: Subject<QueueCommand> = new Subject<QueueCommand>();
  private _queues: BehaviorSubject<Subject<ObservableInput<T>>> = new BehaviorSubject<
    Subject<ObservableInput<T>>
  >(new Subject<ObservableInput<T>>());

  constructor() {
    this._commands.asObservable().subscribe((command) => {
      switch (command && command.action) {
        case QueueActions.RESET:
          this._queues.next(new Subject<ObservableInput<T>>());
          break;
        case QueueActions.ADD:
          this._queues.value.next(command.payload);
          break;
        default:
          this._queues.value.error(`[ObservableQueue] unknown command: ${JSON.stringify(command)}`);
      }
    });
  }

  add(item: ObservableInput<T>): this {
    this._commands.next({ action: QueueActions.ADD, payload: item });
    return this;
  }

  reset(): this {
    this._commands.next({ action: QueueActions.RESET });
    return this;
  }

  asObservable(): Observable<T> {
    return this._queues
      .asObservable()
      .pipe(switchMap((queue) => queue.pipe(concatMap((item) => item))));
  }
}
