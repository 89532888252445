import { HttpClient } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import {
  getBrowserLang,
  Translation,
  TRANSLOCO_CONFIG,
  TRANSLOCO_LOADING_TEMPLATE,
  TRANSLOCO_SCOPE,
  translocoConfig,
  TranslocoLoader,
} from '@ngneat/transloco';
import {
  PERSIST_TRANSLATIONS_STORAGE,
  TranslocoPersistTranslations,
  TranslocoPersistTranslationsModule,
} from '@ngneat/transloco-persist-translations';
import localForage from 'localforage';
import { environment } from '../../../environments/environment';

localForage.config({
  driver: localForage.INDEXEDDB,
  name: 'Transloco',
  storeName: 'translations',
});

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string) {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
  }
}

@NgModule({
  imports: [
    TranslocoPersistTranslationsModule.forRoot({
      loader: TranslocoHttpLoader,
      storage: {
        provide: PERSIST_TRANSLATIONS_STORAGE,
        useValue: localForage,
      },
      storageKey: 'ifelseTranslations',
      ttl: 86400,
    }),
  ],
  exports: [TranslocoPersistTranslationsModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: ['en'],
        fallbackLang: 'en',
        defaultLang: getBrowserLang(),
        missingHandler: {
          // It will use the first language set in the `fallbackLang` property
          useFallbackTranslation: true,
        },
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: environment.production,
      }),
    },
    { provide: TRANSLOCO_SCOPE, useValue: { scope: 'global' }, multi: true },
    { provide: TRANSLOCO_SCOPE, useValue: { scope: 'error' }, multi: true },
    TranslocoPersistTranslations,
    {
      provide: TRANSLOCO_LOADING_TEMPLATE,
      useValue: '<p>loading...</p>',
    },
  ],
})
export class TranslocoRootModule {}
