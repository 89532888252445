/**
 * Single asset ticker, eg. BTC, ETH, USDT
 */
export enum Asset {
  BTC = 'BTC',
  ETH = 'ETH',
  TUSD = 'TUSD',
  USDT = 'USDT',
  BUSD = 'BUSD',
  BNB = 'BNB',
}
