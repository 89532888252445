export enum CandleInterval {
  ONE_MINUTE = '1m',
  THREE_MINUTES = '3m',
  FIVE_MINUTES = '5m',
  FIFTEEN_MINUTES = '15m',
  HALF_HOUR = '30m',
  ONE_HOUR = '1h',
  TWO_HOURS = '2h',
  FOUR_HOURS = '4h',
  SIX_HOURS = '6h',
  EIGHT_HOURS = '8h',
  HALF_DAY = '12h',
  ONE_DAY = '1d',
  THREE_DAYS = '3d',
  ONE_WEEK = '1w',
  // ONE_MONTH = '1M',
}

export enum CandlePart {
  OPEN = 'open',
  CLOSE = 'close',
  HIGH = 'high',
  LOW = 'low',
}

export enum CandleType {
  BULL = 'BULL',
  BEAR = 'BEAR',
}
