import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SettingsState, Theme } from '../../../types/settings.model';
import { SettingsStore } from './settings.store';

@Injectable({ providedIn: 'root' })
export class SettingsQuery extends Query<SettingsState> {
  constructor(protected store: SettingsStore) {
    super(store);
  }
  readonly isMobile$ = this.select('isMobile');
  readonly showLoader$ = this.select('showLoader');
  readonly language$ = this.select('language');
  readonly isDark$: Observable<boolean> = this.select('theme').pipe(
    map((theme) => theme === Theme.DARK),
  );
  readonly theme$ = this.select('theme');

  get theme(): Theme {
    return this.getValue().theme;
  }

  get isDark(): boolean {
    return this.theme === Theme.DARK;
  }
  get isMobile(): boolean {
    return this.getValue().isMobile;
  }
}
