import { CommonKlineTypes, CommonStreamTypes, StreamTopic } from '../base-symbol';
import { CandleInterval } from '../candle-interval.enum';
import { TradeSymbol } from '../trade-symbol.enum';

export function getCandleTypeFromCandleFilter(candleInterval: CandleInterval): CommonKlineTypes {
  switch (candleInterval) {
    case CandleInterval.ONE_MINUTE:
      return 'kline_1m';
    case CandleInterval.THREE_MINUTES:
      return 'kline_3m';
    case CandleInterval.FIVE_MINUTES:
      return 'kline_5m';
    case CandleInterval.FIFTEEN_MINUTES:
      return 'kline_15m';
    case CandleInterval.HALF_HOUR:
      return 'kline_30m';
    case CandleInterval.ONE_HOUR:
      return 'kline_1h';
    case CandleInterval.TWO_HOURS:
      return 'kline_2h';
    case CandleInterval.FOUR_HOURS:
      return 'kline_4h';
    case CandleInterval.SIX_HOURS:
      return 'kline_6h';
    case CandleInterval.EIGHT_HOURS:
      return 'kline_8h';
    case CandleInterval.HALF_DAY:
      return 'kline_12h';
    case CandleInterval.ONE_DAY:
      return 'kline_1d';
    case CandleInterval.THREE_DAYS:
      return 'kline_3d';
    case CandleInterval.ONE_WEEK:
      return 'kline_1w';
    // case CandleInterval.ONE_MONTH:
    //   return 'kline_1M';
    default:
      return 'kline_1m';
  }
}

export function mapToCandleSymbol(symbol: TradeSymbol, candleType: StreamTopic): StreamTopic {
  return `${symbol.toLowerCase()}@${candleType}`;
}
export function getTradeSymbolFromCandleSymbol(candleSymbol: StreamTopic): TradeSymbol {
  return candleSymbol.split('@')[0].toUpperCase() as TradeSymbol;
}

export function isKlineTopic(type: CommonStreamTypes): boolean {
  switch (type) {
    case 'kline_1m':
    case 'kline_3m':
    case 'kline_5m':
    case 'kline_15m':
    case 'kline_30m':
    case 'kline_1h':
    case 'kline_2h':
    case 'kline_4h':
    case 'kline_6h':
    case 'kline_8h':
    case 'kline_12h':
    case 'kline_1d':
    case 'kline_3d':
    case 'kline_1w':
      return true;
    default:
      return false;
  }
}
