import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../state/auth/auth.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private alertService: ToastrService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', err.error.message);
        } else {
          this.handle400(err);
          this.handle401(err);
          this.handle411(err);
          this.handle428(err);
          this.handle500(err);
          this.handle503(err);
        }
        return throwError(err);
      }),
    ) as Observable<HttpEvent<any>>;
  }

  handle401(err: HttpErrorResponse) {
    if (err.status === 401) {
      this.authService.resetAuthState();
      return;
    }
  }
  handle400(err: HttpErrorResponse) {
    if (err.status === 400) {
      this.alertService.error(this.getErrorMessage(err), 'Bad request');
    }
  }

  handle411(err: HttpErrorResponse) {
    if (err.status === 411) {
      this.alertService.error(this.getErrorMessage(err), 'Exchange Access denied', {
        timeOut: 10000,
      });
    }
  }

  handle428(err: HttpErrorResponse) {
    if (err.status === 428) {
      this.alertService.error(this.getErrorMessage(err), 'Validation error', { timeOut: 10000 });
    }
  }
  handle500(err: HttpErrorResponse) {
    if (err.status === 500) {
      this.alertService.error(this.getErrorMessage(err), 'Server error', { timeOut: 10000 });
    }
  }

  handle503(err: HttpErrorResponse) {
    if (err.status === 503) {
      this.alertService.error(err.message, 'Server error', { timeOut: 10000 });
    }
  }

  private getErrorMessage(err: HttpErrorResponse): string {
    let message = '';
    if (typeof err.error.message === 'string') {
      message = err.error.message;
    }
    if (Array.isArray(err.error.message)) {
      err.error.message.forEach((m: any) => {
        if (m) {
          if (typeof m === 'string') {
            message = message + ' \n ' + m;
          } else {
            message = message + ' \n ' + JSON.stringify(m);
          }
        }
      });
    }
    return message;
  }
}
