import { AlertType, IUserEntityDto } from '../../../index';

export interface IUserNotificationEntity {
  id?: number | undefined;
  userId?: number;
  user?: IUserEntityDto;
  isRead: boolean;
  type: AlertType;
  eventCode: NotificationEventCode;
  message: string;
  payload?: Record<string, any>;
  createdDate?: Date;
  updatedDate?: Date;
}

export interface INotificationParamsDto {
  type: AlertType;
  eventCode: NotificationEventCode;
  message: string;
  payload?: Record<string, any>;
}

export interface IUserNotificationParamsDto extends INotificationParamsDto {
  userId: number;
}

export enum NotificationEventCode {
  SERVER_LIFECYCLE_EVENT = 'SERVER_LIFECYCLE_EVENT',
  SERVER_TEST_EVENT = 'SERVER_TEST_EVENT',
  SERVER_ORDER_EVENT = 'SERVER_ORDER_EVENT',
  SERVER_ORDER_REGISTERED_EVENT = 'SERVER_ORDER_REGISTERED_EVENT',
  SERVER_ORDER_CANCELED_EVENT = 'SERVER_ORDER_CANCELED_EVENT',
  SERVER_BATCH_ORDER_CANCELED_EVENT = 'SERVER_BATCH_ORDER_CANCELED_EVENT',
  SERVER_BATCH_ORDER_REGISTERED_EVENT = 'SERVER_BATCH_ORDER_REGISTERED_EVENT',
  EXCHANGE_ORDER_EVENT = 'EXCHANGE_ORDER_EVENT',
  ADMIN_ACTION_EVENT = 'ADMIN_ACTION_EVENT',
}
