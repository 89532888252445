import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ILoginDto } from '@escript/core-models';
import { TranslocoService } from '@ngneat/transloco';
import { TranslocoPersistTranslations } from '@ngneat/transloco-persist-translations';
import { throwError } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { AlertService } from '../../../services/alert.service';
import { AuthApiService } from '../../services/auth.service';
import { AuthStore } from './auth.store';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(
    private authStore: AuthStore,
    private router: Router,
    private authApiService: AuthApiService,
    private alertService: AlertService,
    private translocoService: TranslocoService,
    private translocoPersistTranslations: TranslocoPersistTranslations,
  ) {}

  login(credentials: ILoginDto) {
    return this.authApiService.login(credentials).pipe(
      tap((resp) => {
        if (resp?.profile) {
          this.authStore.update((state) => resp);
        } else {
          const msg = this.translocoService.translate('global.bad-credentials');
          this.alertService.warning(msg);
          return throwError(new Error(msg));
        }
      }),
    );
  }

  logout(): void {
    this.authApiService.logout().pipe(take(1)).subscribe();
    this.resetAuthState();
    this.clearTranslationsCache();
  }

  resetAuthState() {
    this.authStore.reset();
    // this.router.navigateByUrl('/login');
    window.location.reload();
  }

  private clearTranslationsCache() {
    this.translocoPersistTranslations.clearCache();
  }
}
