// eslint-disable-next-line
// eslint-disable-next-line @typescript-eslint/no-var-requires
const packageJson = require('../../../../package.json');
export const environment = {
  baseUrl: location.host,
  appName: 'Trader',
  envName: 'PROD',
  production: true,
  test: false,
  i18nPrefix: '',
  userWsStream: `wss://${location.host}/websocket`,
  exchanges: {
    BINANCE_API_URL: 'https://api.binance.com/api/v3',
    BINANCE_WS_URL: 'wss://stream.binance.com:9443/ws',
    KUCOIN_API_URL: 'https://api.kucoin.com/api/v1',
  },
  versions: {
    app: packageJson.version,
  },
};
