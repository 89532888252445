import {
  MessageType,
  PublicStreamFromExchangeMessageType,
  StreamMessage,
  UserNotificationMessageType,
  UserStreamFromExchangeMessageType,
} from '../../message.model';
import { TradeSymbol } from '../../trade-symbol.enum';
import { IUserEntityDto } from '../entities-dto';

class UserStreamMessageCommon {
  payload: any = {};
  meta: {
    authToken: string;
    exchangeId: number;
    user?: IUserEntityDto;
  } = {
    authToken: '',
    exchangeId: 0,
  };
}

export class GetSymbolTradingInfo extends UserStreamMessageCommon implements StreamMessage {
  type = UserStreamFromExchangeMessageType.GET_SYMBOL_TRADING_INFO;
  payload: TradeSymbol;

  constructor(payload: TradeSymbol, exchangeId: number) {
    super();
    this.payload = payload;
    this.meta.exchangeId = exchangeId;
  }
}

export class GetMiniTickers extends UserStreamMessageCommon implements StreamMessage {
  type = UserStreamFromExchangeMessageType.GET_MINI_TICKERS;

  constructor(
    public payload: TradeSymbol[],
    exchangeId: number,
  ) {
    super();
    this.meta.exchangeId = exchangeId;
  }
}

export class GetUserTradingInfo extends UserStreamMessageCommon implements StreamMessage {
  type = UserStreamFromExchangeMessageType.GET_USER_TRADING_INFO;
  constructor(exchangeId: number) {
    super();
    this.meta.exchangeId = exchangeId;
  }
}

export class GetServerNotifications extends UserStreamMessageCommon implements StreamMessage {
  type = UserNotificationMessageType.GET_SERVER_NOTIFICATION;
}

export class GetServerHeartBeatReport extends UserStreamMessageCommon implements StreamMessage {
  type = PublicStreamFromExchangeMessageType.GET_SERVER_HEART_BEAT_REPORT;
}

export class Ping extends UserStreamMessageCommon implements StreamMessage {
  type = MessageType.PING;
}

export class Pong extends UserStreamMessageCommon implements StreamMessage {
  type = MessageType.PONG;
}

export type UserSteamRequest =
  | GetSymbolTradingInfo
  | GetUserTradingInfo
  | GetServerNotifications
  | Ping
  | GetServerHeartBeatReport;
