export enum Theme {
  DARK = 'BLACK-THEME',
  LIGHT = 'DEFAULT-THEME',
}
export const NIGHT_MODE_THEME = Theme.DARK;

export type Language = 'en' | 'pl';

export interface SettingsState {
  language: Language;
  theme: Theme;
  autoNightMode: boolean;
  hour: number;
  isMobile: boolean;
  showLoader: boolean;
}

export interface WidgetSettings {
  theme: Theme;
  locale: string;
}
