import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Injectable, NgZone } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NIGHT_MODE_THEME, SettingsState, Theme } from '../../../types/settings.model';

export const initialState: SettingsState = {
  language: 'en',
  theme: Theme.LIGHT,
  autoNightMode: false,
  hour: 0,
  isMobile: true,
  showLoader: true,
};

export function createInitialState(): SettingsState {
  return initialState;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'settings', resettable: true })
export class SettingsStore extends Store<SettingsState> {
  constructor(
    private ngZone: NgZone,
    private breakpointObserver: BreakpointObserver,
  ) {
    super(createInitialState());
    this.isMobile$.subscribe((isMobile) => {
      this.update({ isMobile: isMobile });
    });
  }

  public setNightModeTheme() {
    this.update({ theme: NIGHT_MODE_THEME });
  }
  public setDefaultTheme() {
    this.update({ theme: initialState.theme });
  }
  public disableAutoTheme() {
    this.update({ autoNightMode: false });
  }

  public setLoader(showLoader: boolean) {
    this.update({ showLoader });
  }

  private get isMobile$(): Observable<boolean> {
    return this.breakpointObserver
      .observe([
        Breakpoints.Medium,
        Breakpoints.HandsetLandscape,
        Breakpoints.TabletPortrait,
        Breakpoints.TabletLandscape,
        Breakpoints.Tablet,
        Breakpoints.Handset,
      ])
      .pipe(map((res: BreakpointState) => res.matches));
  }
}
